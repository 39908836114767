.homepageHero2 {
  background: url("./images/here_sectionBG.png") no-repeat center center;
  background-size: cover;
}

.signIn {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.linearGradient {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
  padding: 13px 35px;
}

.linearGradient2 {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
}

.custom_button {
  padding: 13px 25px;
}

.sidebar_bg {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
}

.sidebar_scrollbar::-webkit-scrollbar {
  display: none;
}
