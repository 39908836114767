@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.baskerville {
  font-family: "Libre Baskerville", serif;
  font-style: normal;
}

.workSans {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
}

.inter {
  font-family: "Inter", sans-serif;
  font-style: normal;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  margin: unset;
  padding: unset;
  height: 100%;
}

#root {
  display: flex;
  height: 100%;

  .str-chat-channel-list {
    position: fixed;
    z-index: 1;
    width: 0;

    &--open {
      width: 100%;
    }
  }

  .str-chat-channel {
    width: 100%;
  }

  .str-chat__thread {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
  }

  .str-chat__channel-header .str-chat__header-hamburger {
    width: 30px;
    height: 38px;
    padding: var(--xxs-p);
    margin-right: var(--xs-m);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background: transparent;

    &:hover {
      svg path {
        fill: var(--primary-color);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .str-chat-channel-list {
      width: 30%;
      max-width: 420px;
      position: initial;
      z-index: 0;
    }

    .str-chat__thread {
      position: initial;
      z-index: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    .str-chat__thread {
      width: 45%;
    }

    .str-chat__channel-header .str-chat__header-hamburger {
      display: none;
    }
  }
} */

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}
